import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import "./../components/layout.css"

const ContactPage = () => (
    <Layout>
        <Seo title="Manon Lambeens, About, Henri Vandevelde"/>
        <Row className="text-start pl-0">
          <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <h1 className="text-uppercase fs-4 text-dark">Contact</h1>
                <p className="textAbout">For a collaboration or any questions, reach out to me through email or Instagram.
                </p>
                    <p>
                    manon.lambeens@icloud.com
                    </p>
                    <p>
                <a href="https://www.instagram.com/manon.lambeens">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </p>
          </Col>
        </Row>
    </Layout>
)

export default ContactPage;